<template>
  <div class="main-container">
    <div class="left-side-wallet">
      <button @click="goToWallets" type="primary" ref="goToWallets">{{ $t('dashboard.wallet.purchase_bundle') }}</button>
    </div>
    <div class="right-side-history">
      <button @click="goToWalletHistory" ref="goToWalletHistory">{{ $t('dashboard.wallet.wallet_history') }}</button>
      <button @click="goToBundleHistory" ref="goToBundleHistory">{{ $t('dashboard.wallet.bundle_history') }}</button>
    </div>
  </div>
</template>

<script>
export default {

  methods: {
    goToWalletHistory() {
      this.$router.push(`/wallet/wallet-history`).catch((err) => {
        if (err.name !== "NavigationDuplicated") throw err;
      })
    },
    goToBundleHistory() {
      this.$router.push(`/wallet/bundle-history`).catch((err) => {
        if (err.name !== "NavigationDuplicated") throw err;
      })
    },
    goToWallets() {
      this.$router.push(`/wallet`).catch((err) => {
        if (err.name !== "NavigationDuplicated") throw err;
      })
    },
    checkRoute() {
      // dynamic color change
      if (this.$route.path === '/wallet/wallet-history') {
        this.$refs.goToWalletHistory.classList.add('is-active')

      } else if (this.$route.path === '/wallet/bundle-history') {
        this.$refs.goToBundleHistory.classList.add('is-active')

      } else if (this.$route.path === '/wallet') {
        this.$refs.goToWallets.classList.add('is-active')
      }

    }
  },
  mounted() {
    this.checkRoute()
  },
};
</script>
<style scoped>
.main-container {
  display: flex;
  justify-content: space-between;
}

.right-side-history {
  display: flex;
  gap: 1rem;
}

button {
  padding: 0.5em 2.3rem;
  border-radius: 10px;
  border: 1px solid #d4d8e2;
  color: #484a4f;
}

.is-active {
  background-color: #f3f3f4;
  color: #f7921c;
  border-color: #f7921c;

}

@media screen and (max-width: 769px) {
  button {
    padding: 0.5rem .5rem;
    font-size: clamp(0.8rem, .5vw, 1rem);
  }

  .main-container {
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 1rem;
  }

  .right-side-history {
    gap: .5rem;
  }
}
</style>
